// Custom jQuery
// -----------------------------------

(function(window, document, $, undefined) {
    'use strict';

    $(initCustom);

    function initCustom() {
        moment.locale('es');

        //Creados carga de Tabs
        var area = GetURLParameter('area');
        if (area != "") {
            $('#auxNav a[href="#' + area + '"]').tab('show');
            $('.tab-pane.active').removeClass('active show');
            $('#'+area).addClass('active show');
        }


        chatUpdate();

    }

    ObtenerComunicacionesSinLeer();

})(window, document, window.jQuery);
function chatUpdate() {
    if ($(".chat-container")[0]) {
        $('.chat-container').animate({
            scrollTop: $('.chat-container').get(0).scrollHeight
        }, 2000);
    }
}

function GetURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) {
            return sParameterName[1];
        } else {
            return "";
        }
    }
};

function ObtenerComunicacionesSinLeer() {

    $.ajax({
        url: "/Notificaciones/ObtenerComunicacionesSinLeer/",
        type: "post",
        dataType: "json",
        success: function (data) {
            if ($(".creadosNotificacionesSuma").length) {
                $(".creadosNotificacionesSuma").html(data.ContadorSuma);
            }
            if ($(".creadosContadorNotificacionesSinleer").length && data.ContadorNotificacionesSinleer > 0) {
                $(".creadosContadorNotificacionesSinleer").html(data.ContadorNotificacionesSinleer);
            }
            if ($(".creadosContadorConversacionSinLeer").length && data.ContadorConversacionSinLeer > 0) {
                $(".creadosContadorConversacionSinLeer").html(data.ContadorConversacionSinLeer);
            }
        },
        error: function (xhr, ajaxOptions, thrownError) {
            console.log("Hubo un problema al obtener la comunicaciones sin leer", "danger text-center");
        }
    });
}
