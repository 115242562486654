/* Author: Creados Visual - Alejandro Martínez Gómez
 * Description: Configuración de Datatables
 * Author URI: http://www.creados.com
 * Version: 0.18.0223
 * -----------------------------------
 */

(function (window, document, $, undefined) {
    'use strict';

    $(initDatatables);

    function initDatatables() {

        // FILTROS DATATABLE
        // -----------------------------------

        /*
         * Configuración de input para filtrar por búsqueda la datatable .dt-filtered
         */
        $('.dt-searchBar').on('keyup', function () {
            var tableID = '#' + $(this).attr('dt-tableID');
            $(tableID).dataTable().fnFilter(this.value);
        });

        /*
         * Configuración de selects para filtrar por una columna determinada a través del atributo data-colFilter
         * Usa el value
         */
        $('.dt-filter').on("change", function () {
            var colFiltered = $(this).attr('dt-colFilter');
            var tableID = '#' + $(this).attr('dt-tableID');
            if ($(this).val() == -1) {
                $(tableID).dataTable().fnFilter('', colFiltered);
            } else {
                $(tableID).dataTable().fnFilter(this.value, colFiltered);
            }
        });

        /*
         * Configuración de selects para filtrar por una columna determinada a través del atributo data-colFilter
         * Usa la etiqueta en lugar del value como en el caso anterior.
         */
        $('.dt-filter-by-text').on("change", function () {
            var colFiltered = $(this).attr('dt-colFilter');
            var tableID = '#' + $(this).attr('dt-tableID');
            if ($(this).val() == -1 || $(this).val() == null) {
                $(tableID).dataTable().fnFilter('', colFiltered);
            } else {
                if (this.selectedOptions[0] != undefined) {
                    $(tableID).dataTable().fnFilter(this.selectedOptions[0].text, colFiltered);
                }
            }
        });
    };

})(window, document, window.jQuery);


// FUNCIONES DATATABLE

function loadTable(Id_Table, Ajax_Definitions, Columns_Definitions, Fields_Definitions, Table_Type, Buttons_Type, serverSideValue) {

    if (serverSideValue == null || serverSideValue == undefined)
        serverSideValue = false;

    //Tabla
    if (!$.fn.dataTable || $(Id_Table).length == 0 ) return;

    //Definimos el plugin datetime-moment para autodetectar columnas con fecha
    // $.fn.dataTable.moment(format, locale);
    $.fn.dataTable.moment('DD/MM/YYYY', 'es');


    var editor = new $.fn.dataTable.Editor({
        ajax: Ajax_Definitions,
        table: Id_Table,
        idSrc: 'id',
        fields: Fields_Definitions
    });

    //Activate an inline edit on click of a table cell
    //$('.dt-basic').on('click', 'tbody td:not(:first-child)', function (e) {
    //    editor.inline(this);
    //});

    if ($.fn.dataTable.isDataTable(Id_Table)) {
        table = $(Id_Table).DataTable();
    }
    else {

        var dataTableSetup = $.extend(
            {},
            customSetup(Table_Type),
            basicSetup(Id_Table, Ajax_Definitions, Columns_Definitions, serverSideValue)
        );
        
        table = $(Id_Table).DataTable(dataTableSetup);
        loadButtons(table, editor, Buttons_Type);
    }

    //Añadimos los botones fuera de la datatable
    table.buttons().container()
        .appendTo($(Id_Table).siblings('.dt-creados-extras').children('.dt-creados-buttons'));

    //Buscamos el boton de filtros y le añadimos los atributos para que los muestre/oculte
    var customFilter = Id_Table + '-filter';
    $(Id_Table).on('init.dt', function () {
        $(Id_Table+'_wrapper').siblings('.dt-creados-extras').find('.dt-creados-filter')
            .attr('data-toggle', 'collapse')
            .attr('data-target', customFilter);
    });

    if (Table_Type == 'resume') {
        table.on('preDraw', function () {
            //$(Id_Table).addClass('d-none');
            $('.card-body').addClass('whirl double-up');
        })
        .on('draw.dt', function () {
            //$(Id_Table).removeClass('d-none');
            $('.card-body').removeClass('whirl double-up');
        });
    }

}

// Configuración según el tipo de tabla elegida
function customSetup(Table_Type) {
    var config;
    switch (Table_Type) {
        case 'noSearch':
            config = DataTableNoSearch();
            break;
        case 'noPagination':
            config = DataTableNoPagination()
            break;
        case 'MultiSelect':
            config = DataTableSelectMultiple()
            break;
        case 'Selector':
            config = DatatableWithSelector()
            break;
        case 'resume':
            config = DatatableResume()
            break;
        case 'clean':
            config = DataTableClean()
            break;
        case 'defaultTime':
            config = DataTableTime()
            break;
        default:
            config = DataTableDefault()
    }

    return config;
}

// Configuración de botones
function loadButtons(table, editor, Buttons_Type) {
    switch (Buttons_Type) {
        case 'noButtons':
            new $.fn.dataTable.Buttons(table, {
                buttons: []
            });
            break;
        case 'noExport':
            new $.fn.dataTable.Buttons(table, {
                buttons: [
                    {
                        extend: 'colvis',
                        //editor: editor,
                        className: 'btn-lg btn-light',
                        titleAttr: 'Configuración',
                        text: '<i class="icon-settings"></i>',
                        collectionLayout: 'fixed two-column',
                        prefixButtons: [
                            // {
                            //     extend: 'colvisRestore',
                            //     text: 'Reiniciar'
                            // },
                            {
                                extend: 'colvisGroup',
                                text: 'Mostrar Todo',
                                show: ':hidden'
                            },
                            {
                                extend: 'colvisGroup',
                                text: 'Ocultar Todo',
                                hide: ':visible',
                            }
                        ]
                    }
                ]
            });
            break;
        case 'editable':
            new $.fn.dataTable.Buttons(table, {
                buttons: [
                    {
                        extend: 'collection',
                        text: '<i class="icon-printer"></i>',
                        className: 'btn-lg',
                        titleAttr: 'Exportar datos',
                        buttons: [
                            { extend: 'copy', text: 'Copiar' },
                            { extend: 'csvHtml5', text: 'CSV' },
                            { extend: 'excelHtml5' },
                            //{ extend: 'pdf', className: 'btn-sm', title: $('title').text(), text: 'PDF' },
                            { extend: 'print', text: 'Imprimir' }
                        ]
                    },
                    { extend: 'create', editor: editor, className: 'btn-lg btn-default', titleAttr: 'Añadir', text: '<i class="icon-plus"></i>' },
                    { extend: 'editSingle', editor: editor, className: 'btn-lg btn-default', titleAttr: 'Editar', text: '<i class="icon-note"></i>' },
                    { extend: 'remove', editor: editor, className: 'btn-lg btn-default', titleAttr: 'Eliminar', text: '<i class="icon-trash"></i>' },
                    {
                        extend: 'colvis',
                        //editor: editor,
                        className: 'btn-lg btn-default',
                        titleAttr: 'Configuración',
                        text: '<i class="icon-settings"></i>',
                        collectionLayout: 'fixed two-column',
                        prefixButtons: [
                            // {
                            //     extend: 'colvisRestore',
                            //     text: 'Reiniciar'
                            // },
                            {
                                extend: 'colvisGroup',
                                text: 'Mostrar Todo',
                                show: ':hidden'
                            },
                            {
                                extend: 'colvisGroup',
                                text: 'Ocultar Todo',
                                hide: ':visible'
                            }
                        ]
                    }
                ]
            });
            break;
        case 'entregasPDF':
            new $.fn.dataTable.Buttons(table, {
                buttons: [
                    
                    {
                        extend: 'collection',
                        text: '<i class="icon-printer"></i>',
                        className: 'btn-lg',
                        titleAttr: 'Exportar datos',
                        render: function (e, dt, node, config) {

                        },
                        buttons: [
                            { extend: 'copy', text: 'Copiar', footer: true },
                            { extend: 'csvHtml5', text: 'CSV', footer: true },
                            { extend: 'excelHtml5', footer: true },
                            //{ extend: 'pdf', className: 'btn-sm', title: $('title').text(), text: 'PDF' },
                            { extend: 'print', text: 'Imprimir', footer: true },
                            {
                                extend: 'pdfHtml5', text: 'PDF', orientation: 'landscape', pageSize: 'LEGAL',
                                //download: 'open',
                                exportOptions: {
                                    modifier: { search: 'applied' },
                                    columns: ':visible'
                                },
                                customize: function (doc) {
                                    doc.styles.tableHeader = {
                                        color: "white",
                                        fillColor: "#8c9d46",
                                        alignment: 'left'
                                    };
                                    doc.content[1].table.widths = Array(doc.content[1].table.body[0].length + 1).join('*').split('');
                                }
                            }
                        ]
                    },
                    {
                        extend: 'colvis',
                        //editor: editor,
                        className: 'btn-lg btn-light',
                        titleAttr: 'Configuración',
                        text: '<i class="icon-settings"></i>',
                        collectionLayout: 'fixed two-column',
                        prefixButtons: [
                            // {
                            //     extend: 'colvisRestore',
                            //     text: 'Reiniciar'
                            // },
                            {
                                extend: 'colvisGroup',
                                text: 'Mostrar Todo',
                                show: ':hidden'
                            },
                            {
                                extend: 'colvisGroup',
                                text: 'Ocultar Todo',
                                hide: ':visible',
                            }
                        ]
                    }
                ]
            });
            break;
        default:
            new $.fn.dataTable.Buttons(table, {
                buttons: [
                    //{
                    //    text: '<i class="icon-equalizer"></i>',
                    //    className: 'dt-creados-filter',
                    //    action: function (e, dt, node, config) {
                    //        if (this.active()) {
                    //            this.active(false);
                    //        } else {
                    //            this.active(true);
                    //        }
                    //    }
                    //},
                    //{
                    //    text: '<i class="icon-cloud-download"></i>',
                    //    className: 'dt-creados-importar',
                    //    action: function (e, dt, node, config) {
                    //        window.location = '/Entregas/ListadoImportaciones';
                    //    }
                    //},
                    {
                        extend: 'collection',
                        text: '<i class="icon-printer"></i>',
                        className: 'btn-lg',
                        titleAttr: 'Exportar datos',
                        render: function (e, dt, node, config) {
                           
                        },
                        buttons: [
                            { extend: 'copy', text: 'Copiar', footer: true },
                            { extend: 'csvHtml5', text: 'CSV', footer: true },
                            { extend: 'excelHtml5', footer: true },
                            //{ extend: 'pdf', className: 'btn-sm', title: $('title').text(), text: 'PDF' },
                            { extend: 'print', text: 'Imprimir', footer: true }
                        ]
                    },
                    //{ extend: 'create', editor: editor, className: 'btn-lg btn-light', titleAttr: 'Añadir', text: '<i class="icon-plus"></i>' },
                    //{ extend: 'editSingle', editor: editor, className: 'btn-lg btn-light', titleAttr: 'Editar', text: '<i class="icon-note"></i>' },
                    //{ extend: 'remove', editor: editor, className: 'btn-lg btn-light', titleAttr: 'Eliminar', text: '<i class="icon-trash"></i>' },
                    {
                        extend: 'colvis',
                        //editor: editor,
                        className: 'btn-lg btn-light',
                        titleAttr: 'Configuración',
                        text: '<i class="icon-settings"></i>',
                        collectionLayout: 'fixed two-column',
                        prefixButtons: [
                            // {
                            //     extend: 'colvisRestore',
                            //     text: 'Reiniciar'
                            // },
                            {
                                extend: 'colvisGroup',
                                text: 'Mostrar Todo',
                                show: ':hidden'
                            },
                            {
                                extend: 'colvisGroup',
                                text: 'Ocultar Todo',
                                hide: ':visible',
                            }
                        ]
                    }
                    //{
                    //    extend: 'columnsToggle'
                    //}
                ]
            });

    }

}

function DataTableDefault() {
    var config = {
        'searching': true,
        //"bLengthChange": false,
        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "Todo"]],
        'paging': true,  // Table pagination
        'ordering': true,  // Column ordering
        "order": [[2, "asc"]],
        'info': true,  // Bottom left status text
        'responsive': false, // https://datatables.net/extensions/responsive/examples/
        select: {
            style: 'os'
        },
        colReorder: true
    };
    return config;
}
function DataTableTime() {
    var config = {
        'searching': true,
        //"bLengthChange": false,
        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "Todo"]],
        'paging': true,  // Table pagination
        'ordering': true,  // Column ordering
        "order": [[3, "desc"]],
        'info': true,  // Bottom left status text
        'responsive': false, // https://datatables.net/extensions/responsive/examples/
        select: {
            style: 'os'
        },
        colReorder: true
    };
    return config;
}
function DataTableClean() {
    var config = {
        'searching': true,
        "bLengthChange": true,
        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "Todo"]],
        'paging': true,  // Table pagination
        'ordering': false,  // Column ordering
        "order": [[0, "asc"]],
        'info': true,  // Bottom left status text
        'responsive': false, // https://datatables.net/extensions/responsive/examples/
        select: false,
        colReorder: false
    };
    return config;
}

function DataTableNoSearch() {
    var config = {
        'searching': false,
        "bLengthChange": false,
        'paging': true,  // Table pagination
        'ordering': true,  // Column ordering
        "order": [[1, "asc"]],
        'info': true,  // Bottom left status text
        'responsive': false, // https://datatables.net/extensions/responsive/examples/
        stateSave: true,
        select: {
            style: 'os'
        }
    };
    return config;
}

function DataTableNoPagination() {
    var config = {
        'searching': true,
        "bLengthChange": false,
        'paging': false,  // Table pagination
        'ordering': true,  // Column ordering
        "order": [[1, "asc"]],
        'info': true,  // Bottom left status text
        'responsive': false, // https://datatables.net/extensions/responsive/examples/
        stateSave: true,
        select: {
            style: 'os'
        }
    };
    return config;
}

function DataTableSelectMultiple() {
    var config = {
        'searching': true,
        "bLengthChange": false,
        'paging': true,  // Table pagination
        'ordering': true,  // Column ordering
        "order": [[2, "asc"]],
        'info': true,  // Bottom left status text
        'responsive': false, // https://datatables.net/extensions/responsive/examples/
        select: {
            style: 'multi'
        }
    };
    return config;
}

function DatatableWithSelector() {
    var config = {
        'info': true,  // Bottom left status text
        'responsive': false, // https://datatables.net/extensions/responsive/examples/
        columnDefs: [{
            orderable: false,
            className: 'select-checkbox',
            targets: 0
        }],
        select: {
            style: 'multi'
        },
        order: [[1, 'asc']],
        // Note the required keywords between underscores (e.g _MENU_)
        oLanguage: {
            sSearch: 'Buscar:',
            sLengthMenu: '_MENU_',
            sInfo: 'Mostrando del _START_ al _END_  ( _TOTAL_ )',
            sZeroRecords: 'No se han encontrado resultados',
            sInfoEmpty: 'No se han encontrado resultados',
            sInfoFiltered: '(_MAX_)',
            sEmptyTable: ""
        }
    }

    return config;
}

function DatatableResume() {
    var config = {
        'searching': true,
        //"bLengthChange": false,
        "lengthMenu": [[10, 50, 100, 500, 1000, -1], [10, 50, 100, 500, 1000, "Todo"]],
        'paging': true,  // Table pagination
        'ordering': true,  // Column ordering
        "order": [[2, "asc"]],
        'info': true,  // Bottom left status text
        'responsive': false, // https://datatables.net/extensions/responsive/examples/
        select: {
            style: 'os'
        },
        "footerCallback": function (row, data, start, end, display) {
            var api = this.api(), data;
            var columnas = this.data('sumas');
            //var idColumna = this.attr('creados-sumar-columna');
            //var idTotal = this.attr('creados-sumar-columna');
            // Remove the formatting to get integer data for summation
            var intVal = function (i) {
                return  typeof i === 'number' ? i : 0;
                //return typeof i === 'string' ? i.replace(/[\$,]/g, '') * 1 : typeof i === 'number' ? i : 0;
            };

            $.each(columnas, function (index, value) {
                total = api
                    .column(value)
                    .data()
                    .reduce(function (a, b) {
                        //console.log(a + ' + ' + b);
                        return intVal(a) + intVal(b);
                         
                    }, 0);
                total = parseFloat(total);
                //console.log(value + ' : ' + total); 

                $(api.column(value).footer()).html(
                    //'$' + pageTotal + ' ( $' + total + ' total)'
                    parseFloat(total).toFixed(2) + 'Kg'
                );
            });

            // Total over all pages
            //total = api
            //    .column(idColumna)
            //    .data()
            //    .reduce(function (a, b) {
            //        return intVal(a) + intVal(b);
            //    }, 0);

            // Total over this page
            //pageTotal = api
            //    .column(idColumna, { page: 'current' })
            //    .data()
            //    .reduce(function (a, b) {
            //        return intVal(a) + intVal(b);
            //    }, 0);

            // Update footer
            //$(api.column(idTotal).footer()).html(
            //    //'$' + pageTotal + ' ( $' + total + ' total)'
            //    total + 'Kg'
            //);
        },
        "initComplete": function (settings, json) {
            $(Id_Table).removeClass('d-none');
            $('.whirl').removeClass('double-up');
        }
    };
    return config;
}

// Configuración básica de las datatables
function basicSetup(Id_Table, Ajax_Definitions, columns, serverSideValue) {

    var config = {
        //dom: '<"html5buttons text-right"B><"table-responsive full-width mb-lg"t>p',
        dom: '<"table-responsive full-width mb-lg"t><"row mt-2"<"col"l><"col"p>>',
        "ajax": Ajax_Definitions,
        "serverSide": serverSideValue,
        columns: columns, 
        "columnDefs": [
            {
                "targets": 0,
                "visible": false,
                "searchable": false
            }
            //{
            //"targets": 0,
            //"orderable": false,
            //"data": null,
            //"defaultContent": '',
            //"className": 'select-checkbox'
            //}
        ],
        'language': {
            "url": "https://cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json"
        },
        //Preloader
        "initComplete": function (settings, json) {
            $(Id_Table).removeClass('d-none');
            $('.whirl').removeClass('whirl double-up');
        }
    };

    return config;
}

//Cargar la configuración de Ajax según el parámetro URL
function ajaxDefault(URL) {
    return {
        "type": "POST",
        "url": URL, //Direccion del servicio web segido de /Nombre del metodo a llamar
        "data": "{}", //Esto se utiliza si deseamos pasar algun paramentro al metodo del servicio web ejm: {'indentificacion':'1234'}
        "contentType": "application/json; charset=utf-8",
        "dataType": "json", //Esto quiere decir que los datos nos llegaran como un objeto json
    };
}


function ajaxDefaultData(URL, DATA) {
    return {
        "type": "POST",
        "url": URL, //Direccion del servicio web segido de /Nombre del metodo a llamar
        "data": DATA, //Esto se utiliza si deseamos pasar algun paramentro al metodo del servicio web ejm: {'indentificacion':'1234'}        
        "dataType": "json", //Esto quiere decir que los datos nos llegaran como un objeto json
    };
}