/* Author: Creados Visual - Alejandro Martínez Gómez
 * Description: Configuración de TinyMCE
 * Author URI: http://www.creados.com
 * Stylesheet: Creados Stylesheet
 * -----------------------------------
 */

(function (window, document, $, undefined) {

    $(initTinyMCE);

    function initTinyMCE() {

        /*
        * Básica con custom skin
        */
        
    };

})(window, document, window.jQuery);