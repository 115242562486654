/* Author: Creados Visual - Alejandro Martínez Gómez
 * Description: Configuración de Parsley
 * Author URI: http://www.creados.com
 * Version: 0.18.0223
 * -----------------------------------
 */


(function (window, document, $, undefined) {

    $(initParsley);

    function initParsley() {

        // 
        // Se muestra un panel el panel de alerta #customMessage cuando el formulario no pasa la validación.
        // Este evento lo lanza la funcion .validate() al fallar.
        //
        if ($('form').hasClass('customMessage')) {
            $('.customMessage').parsley().on('form:error', function () {
                $("#customMessage").removeClass('hidden');
            });
        }

    };

})(window, document, window.jQuery);

