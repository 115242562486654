/* Author: Creados Visual - Alejandro Martínez Gómez
 * Description: Utilidades para aplicaciones web
 * Author URI: http://www.creados.com
 * Version: 0.18.0223
 * -----------------------------------
 */


(function (window, document, $, undefined) {

    $(initUtilities);

    function initUtilities() {

        // FORMULARIOS EDITABLES
        // -----------------------------------

        /*
         * Habilita/Deshabilita la edición de formularios
         */
        $('.toggleEditable').click(function (e) {
            e.preventDefault();
            $(this).children('.fa').toggleClass('fa-unlock fa-lock');

            //Guardamos la id del formulario desde el atributo data-form
            var formID = '#' + $(this).attr('data-form');
            //Alternamos el estado no-editable para aplicar css
            $(formID).toggleClass("no-editable");

            //Habilitamos/Deshabilitamos los inputs del formulario con id formID
            if ( $(formID).hasClass('no-editable') ) {
                $( formID + ' input:radio').attr('disabled', 'disabled');
                $( formID + ' input').attr('disabled', 'disabled');
                $( formID + ' select').attr('disabled', 'disabled');
            } else {
                $( formID + ' input:radio').removeAttr('disabled');
                $( formID + ' input').removeAttr('disabled');
                $( formID + ' select').removeAttr('disabled');
            }

        });

        /*
         *
         */
        $('#solicitudNav > li').click(function () {
            $(this).siblings('li').addClass('disabled').removeClass('first current');
            $(this).removeClass('disabled').addClass('first current');
        });


        /*
         *
         */
        $('#saveChanges').on("click", "a", function (e) {
            if ($(".toggleEditable .fa-unlock").length && !$(this).hasClass('toggleEditable') && $('.alert').hasClass('hidden')) {
                $('.alert').removeClass('hidden');
            }
        });

        $('input[type=radio][creados-linked-class]').on('change', function () {

                // Almacenamos en option el value del radio button seleccionado
                var option = $(this).val();

                // Almacenamos en linkedClass la clase de los elementos a mostrar/ocultar recibida desde el atributo data-linked-class
                var linkedClass = '.' + $(this).attr('creados-linked-class');
                // Ocultamos todos los elementos relacionados
                $(linkedClass).hide();

                // Buscamos los elementos con la clase guardada en linkedClass que tengan el data-linked-option igual que
                // el valor de opcion selecionada y lo mostramos si existe
                if ($(linkedClass + '[creados-linked-option="' + option + '"]').length) {
                    $(linkedClass + '[creados-linked-option="' + option + '"]').show();
                }
        });

        $(".chosen-select").chosen({ disable_search_threshold: 10 });

        // DATETIMEPICKER
        // -----------------------------------
        if ($.fn.datetimepicker) {

            /*
             * Configuración básica de selector de fecha
             */
            $(".datePicker").datetimepicker({
                locale: 'es',
                icons: {
                    time: 'far fa-clock',
                    date: 'fa fa-calendar',
                    up: 'fa fa-chevron-up',
                    down: 'fa fa-chevron-down',
                    previous: 'fa fa-chevron-left',
                    next: 'fa fa-chevron-right',
                    today: 'fa fa-crosshairs',
                    clear: 'fa fa-trash'
                },
                format: 'L',
                debug: true
            });

            /*
             * Configuración básica de selector de hora
             */
            $('.timePicker').datetimepicker({
                locale: 'es',
                format: 'LT',
                icons: {
                    time: 'fa fa-clock-o',
                    date: 'fa fa-calendar',
                    up: 'fa fa-chevron-up',
                    down: 'fa fa-chevron-down',
                    previous: 'fa fa-chevron-left',
                    next: 'fa fa-chevron-right',
                    today: 'fa fa-crosshairs',
                    clear: 'fa fa-trash'
                }
            });

            /*
             * Configuración básica de selector de fecha y hora
             */
            $(".dateTimePicker").datetimepicker({
                locale: 'es',
                icons: {
                    time: 'fa fa-clock-o',
                    date: 'fa fa-calendar',
                    up: 'fa fa-chevron-up',
                    down: 'fa fa-chevron-down',
                    previous: 'fa fa-chevron-left',
                    next: 'fa fa-chevron-right',
                    today: 'fa fa-crosshairs',
                    clear: 'fa fa-trash'
                }
            });

            /*
             * Configuración de selector de semana
             */
            $(".weekPicker").datetimepicker({
                locale: 'es',
                debug: true,
                icons: {
                    time: 'fa fa-clock-o',
                    date: 'fa fa-calendar',
                    up: 'fa fa-chevron-up',
                    down: 'fa fa-chevron-down',
                    previous: 'fa fa-chevron-left',
                    next: 'fa fa-chevron-right',
                    today: 'fa fa-crosshairs',
                    clear: 'fa fa-trash'
                },
                format: 'DD/MM/YYYY'
            });
            $('#weekPicker').on('dp.change', function (e) {
                value = e.date;
                firstDate = moment(value).day(1).format("DD/MM/YYYY");
                lastDate = moment(value).day(5).format("DD/MM/YYYY");
                $("#weekPicker input").val(firstDate + ' - ' + lastDate);
            });
        }


        // SELECT2
        // -----------------------------------
        //Los select2 presentan problemas con el ancho cuando no tienen elementos, o
        //cuando se inician ocultos. Por ello que tengan todos el atributo width al 100%
        if (!$.fn.select2) return;
            $('.select2').select2({
                theme: 'bootstrap4',
                placeholder: 'Elegir',
                minimumInputLength: 0,
                minimumResultsForSearch: 5,
                language: "es",
                allowClear: false
            });
            $('.select2nosearch').select2({
                theme: 'bootstrap',
                placeholder: 'Elegir',
                minimumInputLength: 0,
                maximumSelectionSize: 5,
                minimumResultsForSearch: -1,
                language: "es-ES",
                allowClear: true,
                width: '100%'
            });
            $('.select2search').select2({
                theme: 'bootstrap',
                minimumInputLength: 3,
                maximumSelectionSize: 5,
                minimumResultsForSearch: -1,
                language: "es",
                width: '100%'
        });
        
    }

})(window, document, window.jQuery);


// FUNCIONES
// -----------------------------------

/*
 * Toggle Loading Button 
 */
function ToggleButtonLoading(id) {
    $('#' + id).toggleClass('disabled');
    $('#' + id).children('.loaded').toggleClass('d-none');
    $('#' + id).children('.loading').toggleClass('d-none');
}

/*
 * Gestión de la visibilidad de los campos del formulario dependiendo de la opción elegida en un select
 */
function linkedVisibility(selectID, mode) {
    // Almacenamos en option el value de la opción seleccionada
    var option = $('#' + selectID + ' option:selected').val();
    // Almacenamos en linkedClass la clase de los elementos a mostrar/ocultar recibida desde el atributo creados-linked-class
    var linkedClass = '.' + $('#' + selectID).attr('creados-linked-class');

    if (typeof mode === "undefined" || mode === null || mode != 'inverse') {
        // Ocultamos todos los elementos relacionados
        $(linkedClass + '[creados-visible-option]').hide();
        // Mostramos aquellos cuyo atributo data-linked-option coincida con el value de la opción seleccionada
        $(linkedClass + '[creados-visible-option="' + option + '"]').show();
    } else {
        // Mostramos todos los elementos relacionados
        $(linkedClass + '[creados-visible-option]').show();
        // Ocultamos aquellos cuyo atributo data-linked-option coincida con el value de la opción seleccionada
        $(linkedClass + '[creados-visible-option="' + option + '"]').hide();
    }
}

/*
 * Gestión del atributo Disabled de los campos del formulario dependiendo de la opción elegida en un select
 */
function linkedDisabled(selectID, mode) {
    // Almacenamos en option el value de la opción seleccionada
    var option = $('#' + selectID + ' option:selected').val();
    // Almacenamos en linkedClass la clase de los elementos a mostrar/ocultar recibida desde el atributo creados-linked-class
    var linkedClass = '.' + $('#' + selectID).attr('creados-linked-class');

    if (typeof mode === "undefined" || mode === null || mode!='inverse') {
        // Habilitamos todos los elementos relacionados
        $(linkedClass + '[creados-disabled-option]').removeAttr('disabled');
        // Deshabilitamos aquellos cuyo atributo creados-disabled-option coincida con el value de la opción seleccionada
        $(linkedClass + '[creados-disabled-option="' + option + '"]').attr('disabled', 'disabled');
    } else {
        // Deshabilitamos todos los elementos relacionados
        $(linkedClass + '[creados-disabled-option]').attr('disabled', 'disabled');
        // Habilitamos aquellos cuyo atributo creados-disabled-option coincida con el value de la opción seleccionada
        $(linkedClass + '[creados-disabled-option="' + option + '"]').removeAttr('disabled', 'disabled');
    }
}


/*
 * EJEMPLO
 **************************************
    //
    //HTML de la tabla
    //
    <tbody>
        <tr>
            <td>
                <a href="#" id="id" onclick="linkedInput(this.id)">Botón</a>
            </td>
            <td class="data">Datos</td>
        </tr>
    </tbody>

    //
    //HTML INPUT DESTINO
    //
    <input type="text" class="form-control" id="linked-responsable"></input>
 *
 */
function linkedInput(selectID) {
    var linkedData = $('#' + selectID).closest('tr').find('.data').text();
    var outputID = $('#' + selectID).attr('creados-linked-data');
    $('#' + outputID).text(linkedData);
}

function borradoYcargaDeDatos(idDiv, data) {
    if ($("#" + idDiv).length) {
        $("#" + idDiv).empty();
        document.getElementById(idDiv).innerHTML = data;
    }
}

function inicializacionSelect2ajaxGeneral(nombreClase, urlConsulta, idInputPadre) {

    $("." + nombreClase).select2({
        allowClear: true,
        placeholder: "Buscar",
        minimumInputLength: -1,
        theme: 'bootstrap',
        dataType: 'json',
        quietMillis: 250,
        width: '100%',

        language: {
            noResults: () => { return 'Sin resultados'; },
            searching: () => { return 'Buscando...'; },
            errorLoading: () => { return 'Buscando...'; }
        },
        ajax: {
            url: urlConsulta,
            type: "POST",
            dataType: 'json',
            data: function (params) {
                return {
                    Id: $('#' + idInputPadre).val(),
                    Cadena: params.term
                };
            },
            processResults: function (data) {
                return {
                    results: data.listado
                };
            },
        }
    });
}